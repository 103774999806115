import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Template from "@/views/Template";
import ProductPresentation from "@/components/ProductPresentation";
import Typography from "@/components/Typography";
import InvestTime from "@/containers/InvestTime";
export const _frontmatter = {
  "title": "IRPF",
  "path": "/declar-irpf",
  "date": "2019-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Template;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProductPresentation contactLink="https://wa.me/5581994019577" knowMoreLink="https://www.instagram.com/leancontabilidade" description="Entre em contato, nosso time de especialistas está pronto para lhe atender." title={() => <>
      Que tal contar com uma{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        assessoria especializada
      </Typography>{" "}
      para cuidar da sua declaração anual de{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        Imposto de Renda
      </Typography>
      ?
    </>} mdxType="ProductPresentation" />
    <InvestTime mdxType="InvestTime" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      